<template>
  <span>
    <v-btn style="margin-left: 5px; margin-right: 5px;" :disabled="disabled" :fab="!!fabicon" :small="this.sizes.small" :x-small="this.sizes.xsmall" :color="this.color || 'success'" @click="openDialog()">
      <span v-if="!fabicon">{{this.buttontext}}</span>
      <v-icon v-else>{{fabicon}}</v-icon>
      </v-btn>
    <v-dialog
        v-model="dialog.isOpen"
        persistent
        width="500px"
    >
      <v-card>
        <v-card-title class="text-h5 d-flex flex-column align-center justify-center">
          <v-progress-circular
              indeterminate
              color="green"
              v-if="dialog.awaitingScanResult"
              style="margin-top: 20px; margin-bottom: 5px;"
          ></v-progress-circular>
          <b>{{dialog.text}}</b>
          <v-btn v-if="!usePin" small color="info" style="margin-top: 15px;" @click="openUsePin()">Use PIN Instead</v-btn>
          <span v-else>
            <v-text-field type="password" inputmode="numeric" label="PIN" v-model="userPin"></v-text-field>
            <v-btn color="error" small @click="closeUsePin()">Cancel</v-btn>
            <v-btn :disabled="finalButtonDisable" style="margin-left: 5px;" color="info" small @click="attemptPINAuth()">Submit</v-btn>
          </span>
        </v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="closeDialog()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import scanSystem from "../plugins/scanSystem"
export default {
  name: 'authorizer',
  props: {
    authorization: Object,
    buttontext: String,
    color: String,
    fabicon: String,
    disabled: Boolean,
    size: String
  },
  data: () => ({
    dialog: {
      isOpen: false,
      awaitingScanResult: false,
      text: '',
    },
    usePin: false,
    sizes: {
      xsmall: true,
      small: false
    },
    userPin: '',
    userName: '',
    scanString: '',
    prevScanBus: null,
    finalButtonDisable: false
  }),
  mounted(){
    if(this.size){
      if(this.size==="small"){
        this.sizes.small = true;
        this.sizes.xsmall = false;
      }
      else if(this.size==="xsmall"){
        this.sizes.xsmall = true;
        this.sizes.small = false;
      }
      return;
    }
    this.sizes.xsmall = true;
    this.sizes.small = false;
  },
  computed: {
    ...mapGetters([
      'getId',
      'getEndpoint',
      'lookupUserName',
      'getUser',
      'scanBus',
      'lookupUsername',
      'printInProgress'
      ]),
  },
  methods: {
    ...mapMutations([
      'lockGlobalQueryBc',
      'unlockGlobalQueryBc',
      'setScanBusData',
      'setScanBusType',
      'resetScanBus',
      'lockScanBus',
      'unlockScanBus',
      'setPrintInProgress'
    ]),
    async openDialog(){
      try {
        this.prevScanBus = {...this.scanBus}
        this.lockScanBus('authorizer')
        this.dialog.isOpen = true
        this.dialog.text = "Awaiting Scan"
        window.addEventListener('keypress', this.scanListener);
      } catch (error) {
        console.log(error)
      }
    },
    closeDialog(){
      this.closeUsePin()
      this.dialog = {
        isOpen: false,
        awaitingScan: '',
        text: '',
      }
      this.scanString = ''
      window.removeEventListener('keypress', this.scanListener)
      this.unlockScanBus(this.prevScanBus)
    },
    scanListener(e){
      //SID Listener
      if(this.getUser && this.scanBus.locked && this.scanBus.type=="authorizer"){ //to use barcode services an invoker must use the scanbus and specify a type, else the daemon will attempt to validate for a job
        if(e.key == 'Enter'){ // The barcode scanner sends the Enter key at the end of a read op.
          let validatedType = scanSystem.validateType(this.scanString)
          if(validatedType==="VSID"){
            this.$emit('response', {pin:null,vsidToken:this.scanString.replace(/\\\/\/\\=/,''), id: this.getId})
            this.closeDialog()
          }else{
            this.resetScanBus()
            console.log("🔁")
            this.closeDialog()
          }
        }else{
          if(!this.scanString) this.scanString = ""
          this.scanString = this.scanString + e.key
        }
      }
    },
    attemptPINAuth(){
      this.finalButtonDisable = true
      this.$emit('response', {pin:this.userPin,vsidToken:null,id:this.getUser.id})
      this.closeDialog()
    },
    openUsePin(){
      this.usePin = true
    },
    closeUsePin(){
      this.usePin = false
      this.userPin = ''
      this.userName = ''
      this.finalButtonDisable = false
    },
  }
}
</script>
